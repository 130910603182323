<template>
    <v-container class="my-10 my-md-16" v-if="user">
        <v-row class="d-flex flex-row pa-3 heading">
            <v-col>
                <h1 class="text-left">{{ $t('userProfile.greeting') }} {{ user.first_name }}!</h1>
                <p class="text-left">
                    {{ $t('userProfile.description') }}
                </p>
            </v-col>
        </v-row>


        <div class="card border rounded-xl mx-auto my-8 pa-3">
            <v-row class="py-4 px-3">
                <div
                    class="col-12 col-md-4 d-flex flex-column flex-md-row justify-center align-center align-md-left py-0 mb-5 mb-md-0">
                    <v-img :src="user.logo_path ? user.logo_path : require('../assets/illustrations/no-photo.jpg')"
                        class="rounded-pill img-profile" cover @click="triggerFileInput" style="cursor: pointer;" />
                    <input type="file" ref="fileInput" @change="uploadLogo" style="display: none;" accept="image/*" />
                    <v-list>
                        <v-col>
                            <v-row class=" ml-md-4 " justify="start">
                                <v-col cursor-pointer class="settings text-center text-md-left"
                                    @click="triggerFileInput">
                                    <v-btn class="pa-0 button">
                                        {{ $t('userProfile.edit') }}
                                    </v-btn>
                                </v-col>
                            </v-row>
                            <v-row class="  ml-md-4" justify="start">
                                <v-col cursor-pointer class="settings  text-center text-md-left"
                                    @click.stop="dialog = true">
                                    <v-btn class="pa-0 button">{{ $t('userProfile.dialogTitle') }}</v-btn>
                                </v-col>
                            </v-row>
                        </v-col>
                    </v-list>
                </div>
                <div class="d-block d-sm-flex col-12 col-md-8 py-0">
                    <v-list class="col-12 col-sm-6 py-0 py-sm-1">
                        <v-col>
                            <v-row justify="center" class="text-left ml-md-4">
                                <v-col cols="3" sm="3" md="3" class="text-label">
                                    {{ $t('userProfile.labelName') }}
                                </v-col>
                                <v-col cols="6" sm="6" md="9"
                                    class="text-right text-sm-left text-container text-disabled">
                                    {{ user.first_name + " " + user.last_name }}
                                </v-col>
                            </v-row>
                            <v-row justify="center" class="text-left ml-md-4">
                                <v-col cols="3" sm="3" md="3" class="text-label">
                                    {{ $t('userProfile.labelEmail') }}
                                </v-col>
                                <v-col cols="6" sm="6" md="9"
                                    class="text-right text-sm-left text-container text-disabled">
                                    {{ user.email }}
                                </v-col>
                            </v-row>
                        </v-col>
                    </v-list>

                    <v-list class="col-12 col-sm-6 py-0 py-sm-1">
                        <v-col>
                            <v-row justify="center" class="text-left ml-md-4">
                                <v-col cols="3" sm="3" md="3" class="text-label">
                                    {{ $t('userProfile.labelPhone') }}
                                </v-col>
                                <v-col cols="6" sm="6" md="9"
                                    class="text-right text-sm-left text-container text-disabled">
                                    {{ user.phone_code + " " + user.phone_number }}
                                </v-col>
                            </v-row>
                            <v-row justify="center" class="text-left ml-md-4">
                                <v-col cols="3" sm="3" md="3" class="text-label">
                                    {{ $t('userProfile.labelRol') }}
                                </v-col>
                                <v-col cols="6" sm="6" md="9"
                                    class="text-right text-sm-left text-container text-disabled">
                                    {{ user.role.name }}
                                </v-col>
                            </v-row>
                        </v-col>
                    </v-list>

                </div>


            </v-row>
            <v-dialog v-model="dialog" transition="dialog-top-transition" max-width="600">
                <v-card>
                    <v-card-text class="py-0 px-0">
                        <v-form ref="formChangePass" lazy-validation>
                            <v-alert border="right" colored-border color="#8e84c0" elevation="0"
                                class="col-12 px-10 py-10 mb-0">
                                <div class="text-left">
                                    <v-row class="mb-0">
                                        <v-icon class="ml-2 mt-n1" size="26px" color="#454547">
                                            mdi-shield-account
                                        </v-icon>
                                        <h2 class="mb-2 mt-1 ml-2 mt-0 title-modal">{{ $t('userProfile.dialogTitle') }}
                                        </h2>
                                    </v-row>
                                    <p class="mb-10 desc">{{ $t('userProfile.dialogDescription') }}</p>

                                    <v-text-field v-model="last_password"
                                        :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
                                        :rules="[rules.required, rules.min]" :type="show1 ? 'text' : 'password'"
                                        :label="$t('userProfile.currentPassword')" counter color="#8e84c0" outlined
                                        clearable @click:append="show1 = !show1"></v-text-field>

                                    <v-text-field v-model="password" :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
                                        :rules="[rules.required, rules.min]" :type="show1 ? 'text' : 'password'"
                                        :label="$t('userProfile.password')" counter color="#8e84c0" outlined clearable
                                        @click:append="show1 = !show1"></v-text-field>

                                    <v-text-field v-model="password_confirm"
                                        :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
                                        :rules="[rules.required, rules.min, rules.passwordMatch]"
                                        :type="show1 ? 'text' : 'password'" :label="$t('userProfile.confirm-password')"
                                        counter color="#8e84c0" outlined clearable
                                        @click:append="show1 = !show1"></v-text-field>
                                </div>

                                <div class="text-right">
                                    <v-btn :disabled="!isFormValid" color="#8e84c0" @click="updatePass"
                                        class="ml-0 white--text link ma-2 rounded-md px-16 col-12 col-md-5">
                                        <v-icon left color="white">
                                            mdi-account-key-outline
                                        </v-icon>
                                        {{ $t('userProfile.savePassword') }}
                                    </v-btn>
                                </div>
                            </v-alert>
                        </v-form>
                    </v-card-text>
                </v-card>
            </v-dialog>
        </div>


        <template>
            <v-data-table :headers="headers" :items="deviceList" :items-per-page="5"
                class="elevation-0 card rounded-xl pa-3 mb-16"></v-data-table>
        </template>

    </v-container>
</template>
<script>
import axios from "@/axios.js";

export default {
    watch: {
        '$i18n.locale'() {
            this.updateHeaders();
        }
    },
    name: "UserProfile",
    data() {
        return {
            show1: false,
            last_password: '',
            password: '',
            password_confirm: '',

            user: null,
            dialog: false,
            last_session: null,
            rules: {
                required: value => !!value || this.$t('navbar.required'),
                min: v => v.length >= 8 || this.$t('navbar.min'),
                passwordMatch: value => value === this.password || this.$t('navbar.password-match'),
                passwordMatch2: value => value === this.newPassword || this.$t('navbar.password-match'),
            },

            headers: [],
            deviceList: [],
            access: null,
        };
    },
    computed: {
        isFormValid() {
            return (
                this.rules.required(this.password) === true &&
                this.rules.min(this.password) === true &&
                this.rules.required(this.last_password) === true &&
                this.rules.min(this.last_password) === true &&
                this.rules.required(this.password_confirm) === true &&
                this.rules.min(this.password_confirm) === true &&
                this.rules.passwordMatch(this.password_confirm) === true
            );
        },
    },
    methods: {
        async fetchAccessData() {
            try {
                this.checkUser();
                this.checkLanguage();
                const response = await axios.get('/access-data');
                this.access = response.data;
                this.deviceStats();
                this.updateHeaders();
            } catch (error) {
                console.error('Error fetching access data:', error);
            }
        },

        checkUser() {
            let user = localStorage.getItem("user");
            if (user) {
                this.user = JSON.parse(user);
            }
        },

        checkLanguage() {
            const storedLocale = localStorage.getItem("locale");
            this.lang = storedLocale;
        },

        triggerFileInput() {
            this.$refs.fileInput.click();
        },

        async uploadLogo(event) {
            const file = event.target.files[0];
            if (file) {
                const formData = new FormData();
                formData.append('file', file);

                try {
                    await axios.post('/upload-logo', formData, {
                        headers: {
                            'Content-Type': 'multipart/form-data',
                            'Authorization': `Bearer ${localStorage.getItem('token')}`
                        }
                    });
                    const response = await axios.get('/get-my-info', {
                        headers: {
                            'Authorization': `Bearer ${localStorage.getItem('token')}`
                        }
                    });
                    this.user.logo_path = response.data.logo_path;
                    localStorage.setItem("user", JSON.stringify(this.user));
                    this.$root.$emit('user-updated', this.user);

                    this.$vs.notify({
                        title: this.$t('userProfile.uploadImage-notifySuccess-title'),
                        text: this.$t('userProfile.uploadImage-notifySuccess-text'),
                        color: 'success',
                        icon: 'check_box',
                        position: 'bottom-center',
                        time: 5000,
                    });

                } catch (error) {
                    this.$vs.notify({
                        title: this.$t('userProfile.uploadImage-notifyError-title'),
                        text: this.$t('userProfile.uploadImage-notifyError-text'),
                        color: 'danger',
                        icon: 'warning',
                        position: 'bottom-center',
                        time: 5000,
                    });
                    console.error('Upload Error:', error);
                }
            }
        },
        extractDevice(userAgent) {
            const deviceRegex = /\(([^)]+)\)/;
            const match = userAgent.match(deviceRegex);
            return match ? match[1].split(";")[0] : userAgent;
        },
        deviceStats() {
            if (!this.access) return;
            const deviceDetails = [];

            this.access.forEach((entry) => {
                const device = this.extractDevice(entry.divice);
                const date = new Date(entry.time);

                deviceDetails.unshift({
                    device: device,
                    lastConnection: date.toISOString(),
                    city: entry.city,
                    region: entry.region,
                    country: entry.country,
                });
            });

            this.deviceList = deviceDetails.map(device => ({
        ...device,
            lastConnection: new Date(device.lastConnection).toLocaleString('es-ES', {
            year: 'numeric',
            month: '2-digit',
            day: '2-digit',
            hour: '2-digit',
            minute: '2-digit',
            second: '2-digit',
            hour12: false
        })
    }));
        },
        async updatePass() {
            if (this.isFormValid) {
                const formData = {
                    current_password: this.last_password,
                    new_password: this.password,
                    new_password_confirmation: this.password_confirm,
                };
                try {
                    const response = await axios.post('/update-pass', formData);

                    if (response.data.success) {
                        this.$vs.notify({
                            title: this.$t('userProfile.updatePass-notifySuccess-title'),
                            text: this.$t('userProfile.updatePass-notifySuccess-text'),
                            color: 'success',
                            icon: 'check_box',
                            position: 'bottom-center',
                            time: 5000,
                        });
                        this.dialog = false;
                        this.last_password = ""
                        this.password = ""
                        this.password_confirm = ""
                        this.$refs.formChangePass.resetValidation();
                    }
                } catch (error) {
                    if (error.response && error.response.status === 400) {
                        this.$vs.notify({
                            title: this.$t('userProfile.updatePass-notifyError-title'),
                            text: this.$t('userProfile.updatePass-notifyError-text'),
                            color: 'danger',
                            icon: 'error',
                            position: 'bottom-center',
                            time: 5000,
                        });
                    }
                }
            } else {
                this.$vs.notify({
                    title: this.$t('userProfile.updatePass-notifyInvalid-title'),
                    text: this.$t('userProfile.updatePass-notifyInvalid-text'),
                    color: 'danger',
                    icon: 'error_outline',
                    position: 'bottom-center',
                    time: 5000,
                });
            }
        },
        updateHeaders() {
            this.headers = [
                { text: this.$t('userProfile.headers-device'), value: 'device' },
                { text: this.$t('userProfile.headers-lastConnection'), value: 'lastConnection' },
                { text: this.$t('userProfile.headers-city'), value: 'city' },
                { text: this.$t('userProfile.headers-region'), value: 'region' },
                { text: this.$t('userProfile.headers-country'), value: 'country' },
            ];
        }
    },
    mounted() {
        this.fetchAccessData();
    },
};
</script>

<style scoped>
.text-label,
.settings {
    color: #8e84c0;
    font-size: small;
    font-weight: 700;
    text-wrap: nowrap;
    text-align: left
}

.settings {
    cursor: pointer;
    color: #72dd89;
    padding: 3px 0px
}

.text-container {
    color: #8a8a8a;
    font-size: small;
    font-weight: 300;
}

.heading h1 {
    font-size: 1.5rem;
    margin-bottom: 5px;
}

.heading p {
    font-size: 0.8rem;
    color: rgb(151, 138, 206);
    font-weight: 600;
}

.card {
    border-radius: 10px;
    gap: 20px;
    color: rgb(70, 70, 70);
    -webkit-box-shadow: 0px 7px 16px 0px rgba(224, 224, 224, 1) !important;
    -moz-box-shadow: 0px 7px 16px 0px rgba(224, 224, 224, 1) !important;
    box-shadow: 0px 7px 16px 0px rgba(224, 224, 224, 1) !important;
}

.border {
    border: none;
    border-left: 5px solid #afa2e5;
}

.profile p {
    font-size: 0.7rem;
    max-width: 250px;
    margin: auto;
}

.profile img {
    font-size: 0.8rem;
    object-fit: cover;
}

.button {
    width: fit-content;
    border: none;
    padding: 10px !important;
    background: #8e84c013 !important;
    color: #8e84c0;
    cursor: pointer;
    box-shadow: none;
    margin: auto;
    font-size: small;
    font-weight: 700;
    letter-spacing: normal;
}

.img-profile {
    max-width: 100px !important;
    max-height: 100px !important;
    width: 100%;
    height: 100%;
}
</style>